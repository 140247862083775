import cx from 'classnames'

type TitleProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>

export const PageTitle = ({ className, ...rest }: TitleProps) => (
  <h1 className={cx('font-bold text-xl md:text-3xl')} {...rest} />
)


export const SectionTitle = ({ className, ...rest }: TitleProps) => (
  <h2 className={cx('font-semibold text-lg md:text-xl', className)} {...rest} />
)
