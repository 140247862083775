import cx from 'classnames'
import { Menu, useMenu } from 'components'

type Item = {
  icon?: React.ReactNode
  title: string
  selected?: boolean
  callback: () => void
}

type MenuItemsProps = {
  title?: string
  items: Item[]
  closeTitle?: string
}

export const MenuItems = ({ title = 'Options', items, closeTitle = 'Cancel' }: MenuItemsProps) => {
  const { exit } = useMenu()

  const handleClick = (callback: () => void) => {
    exit()
    callback()
  }

  return (
    <div className="p-4">
      <h1 className="pb-4 text-xs font-semibold text-neutral-400">{title}</h1>
      <ul>
        {items.map(({ icon, title, selected, callback }) => (
          <li key={title} className={cx('rounded-lg', selected && 'bg-neutral-600')}>
            <button
              onClick={() => handleClick(callback)}
              className="flex w-full flex-row items-center gap-2 p-2 text-left"
            >
              {icon ? (
                <>
                  <div>{icon}</div>
                  <div>{title}</div>
                </>
              ) : (
                title
              )}
            </button>
          </li>
        ))}
      </ul>
      <Menu.Close className="w-full p-4 text-center text-sm font-semibold text-neutral-400 focus:outline-none">
        {closeTitle}
      </Menu.Close>
    </div>
  )
}
