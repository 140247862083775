import { getMillisecondsFromExpiry, getTimeFromMilliseconds } from 'lib'
import { useState } from 'react'
import useInterval from './use-interval'

type TimerTime = {
  milliseconds: number
  seconds: number
  minutes: number
  hours: number
  days: number
}

export type UseTimerValue = TimerTime & {
  isRunning: boolean
  start: (d: Date) => void
  pause: () => void
  resume: () => void
}

type UseTimerProps = {
  autoStart?: boolean
  expires?: Date
  onExpire?: () => void
}

const now = () => new Date()

export const useTimer = ({ autoStart = true, expires: initial, onExpire }: UseTimerProps): UseTimerValue => {
  const [expires, setExpires] = useState<Date | undefined>(initial)
  const [paused, setPaused] = useState<Date>()
  const [isRunning, setIsRunning] = useState(autoStart)
  const [ms, setMS] = useState(0)

  const handleExpire = () => {
    setIsRunning(false)
    onExpire && onExpire()
  }

  useInterval(
    () => {
      if (!isRunning || !expires) {
        return
      }
      const ms = getMillisecondsFromExpiry(expires)
      setMS(ms)
      if (ms <= 0) {
        handleExpire()
      }
    },
    isRunning ? 34 : null
  )

  const start = (d: Date) => {
    setExpires(d)
    setIsRunning(true)
  }

  const pause = () => {
    setPaused(now())
    setIsRunning(false)
  }

  const resume = () => {
    if (!paused || !expires) {
      return
    }
    const diff = expires.getTime() - paused.getTime()
    setExpires(new Date(now().getTime() + diff))
    setPaused(undefined)
    setIsRunning(true)
  }

  return {
    ...getTimeFromMilliseconds(ms),
    isRunning,
    start,
    pause,
    resume,
  }
}
