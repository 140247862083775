import { Tab, Tabs } from '@nextui-org/react'
import cx from 'classnames'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { Key } from 'react'
import { UrlObject } from 'url'

declare type Url = string | UrlObject

type Tab = {
  name: string
  current: boolean
  href: string | Url
}

type TabBarProps = {
  className?: string
  tabs: Tab[]
}

export const TabBar = ({ className, tabs }: TabBarProps) => {
  const selectedKey = tabs.find((t) => t.current)?.name
  const router = useRouter()
  const onSelectionChange = (key: Key) => {
    const tab = tabs.find((t) => t.name === key)
    if (tab) {
      router.push(tab.href)
    }
  }

  return (
    <div className={cx('flex w-full flex-col', className)}>
      <Tabs
        aria-label="Exercise view options"
        className="justify-center"
        classNames={{
          tabList: 'w-full sm:w-1/2 lg:w-1/3',
        }}
        onSelectionChange={onSelectionChange}
        selectedKey={selectedKey}
        size="md"
        variant="light"
      >
        {tabs.map((tab) => (
          <Tab key={tab.name} title={tab.name} />
        ))}
      </Tabs>
    </div>
  )
}
