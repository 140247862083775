export const getMillisecondsFromExpiry = (expiry: Date) => {
  const ms = expiry.getTime() - new Date().getTime()
  return ms > 0 ? ms : 0
}

export const getTimeFromMilliseconds = (ms: number) => {
  const days = Math.floor(ms / (1000 * 60 * 60 * 24))
  const hours = Math.floor(((ms / 1000) % (60 * 60 * 24)) / (60 * 60))
  const minutes = Math.floor(((ms / 1000) % (60 * 60)) / 60)
  const seconds = Math.floor((ms / 1000) % 60)
  const milliseconds = Math.floor(ms % 1000)

  return {
    milliseconds,
    seconds,
    minutes,
    hours,
    days,
  }
}

export const now = () => new Date()
