import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client'
import { UseTimerValue, useTimer } from 'hooks'
import {
  CreateExerciseLogMutation,
  CreateExerciseLogMutationVariables,
  CurrentQuery,
  ExerciseLog,
  ExerciseLogSetInput,
  StopMutation,
  Unit,
  WorkoutProviderUpdateExerciseLogMutation,
  WorkoutProviderUpdateExerciseLogMutationVariables,
  WorkoutExerciseSet,
  WorkoutLogExercise,
  WorkoutProviderCreateExerciseLogMutationVariables,
  WorkoutProviderCreateExerciseLogMutation,
} from 'lib/generated'
import { createContext, useContext, useEffect, useState } from 'react'
import { current } from 'tailwindcss/colors'
import { v4 as uuid } from 'uuid'

/**
 * Fetch the data for the current workout.
 *
 * The caller should not need to run this query themselves, rather, they can
 * just listen for the current workout that is fetched from this. To change the
 * workout, `set()` a new ID or clear the current workout.
 *
 */
const currentQuery = gql`
  query Current {
    current {
      id
      workout {
        name
        exercises {
          id
          exercise {
            id
            name
          }
          sets {
            id
            setNumber
            reps {
              lower
              upper
            }
            weight
          }
        }
      }
      exercises {
        id
        exercise {
          id
          name
        }
        log {
          id
          note
          sets {
            setNumber
            reps
            weight
            unit
            repsPlaceholder
            weightPlaceholder
            complete
          }
        }
      }
    }
  }
`

const createExerciseLogMutation = gql`
  mutation WorkoutProviderCreateExerciseLog($exerciseLog: CreateExerciseLogInput) {
    createExerciseLog(exerciseLog: $exerciseLog) {
      id
      sets {
        setNumber
        reps
        weight
        unit
        repsPlaceholder
        weightPlaceholder
        complete
      }
    }
  }
`

const updateExerciseLogMutation = gql`
  mutation WorkoutProviderUpdateExerciseLog($exerciseLog: UpdateExerciseLogInput) {
    updateExerciseLog(exerciseLog: $exerciseLog) {
      id
      sets {
        setNumber
        reps
        weight
        unit
        repsPlaceholder
        weightPlaceholder
        complete
      }
    }
  }
`

const stopWorkout = gql`
  mutation Stop {
    stop {
      id
    }
  }
`

type Current = CurrentQuery['current']

type Exercise = {
  id: string
  name: string
  exerciseId: string
}

export type ExerciseLogSet = {
  id: string
  reps: string
  weight: string
  unit: Unit
  complete: boolean

  repsPlaceholder?: string
  weightPlaceholder?: string
}

/**
 * The root state of the current workout and everything the app needs to
 * calculate what the user is doing.
 */
type State = {
  timeStop?: Date
  currentExerciseIndex: number
}

export type ExerciseState = {
  exercise: Exercise
  note?: String
  sets: ExerciseLogSet[]
  removeSet: (l: ExerciseLogSet) => void
  addSet: () => void
  updateSet: (l: ExerciseLogSet) => void
  updateNote: (value: string) => void
}

type Context = State & {
  current?: Current
  error?: ApolloError
  loading: boolean
  isNext: boolean
  isPrevious: boolean
  exercise?: ExerciseState
  timer: UseTimerValue
  timerInterval: number
  refresh: () => Promise<Current | undefined>
  next: () => void
  previous: () => void
  stop: () => void
  setTimerInterval: (interval: number) => void
}

// @ts-ignore
export const WorkoutContext = createContext<Context>(undefined)
export const useWorkout = (): Context => useContext(WorkoutContext)

/**
 * The props for the Provider
 */
type Props = {
  children?: React.ReactNode
}

export const WorkoutProvider = ({ children }: Props) => {
  const [state, setState] = useState<State>({
    timeStop: undefined,
    currentExerciseIndex: 0,
  })
  const timer = useTimer({ expires: state.timeStop })

  const [recordedNotes, setRecordedNotes] = useState<Record<string, string>>({})
  const [recordedExerciseLogIDs, setRecordedExerciseLogIDs] = useState<Set<string>>(new Set())
  const [recordedExerciseSets, setRecordedExerciseSets] = useState<Record<string, ExerciseLogSet[]>>({})
  const [timerInterval, setTimerInterval] = useState<number>(60)

  const [queryCurrent, { data, loading, error }] = useLazyQuery<CurrentQuery>(currentQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ current }) => {
      setRecordedNotes(
        current?.exercises?.reduce((logs, e, i) => {
          const existingExerciseLog = current?.exercises[i]?.log
          return {
            ...logs,
            [e.id]: existingExerciseLog?.note || '',
          }
        }, {}) || {}
      )
      // Set the existing exercise log IDs on existingExerciseLogs
      const existingExerciseLogIDs: Array<string> =
        current?.exercises.map((e) => e.log?.id || '').filter((logID) => logID) || []
      setRecordedExerciseLogIDs(
        (existingExerciseLogs) => new Set([...Array.from(existingExerciseLogs), ...existingExerciseLogIDs])
      )

      setRecordedExerciseSets(
        current?.exercises?.reduce((logs, e, i) => {
          const existingExerciseLog = current?.exercises[i]?.log
          const hasRecordedSets = hasSets(existingExerciseLog as ExerciseLog)
          const previousSet = current?.exercises[i]?.log?.sets.slice().pop()
          const weightPlaceholder = hasRecordedSets ? previousSet?.weight?.toString() : '0'
          return {
            ...logs,
            [e.id]: hasRecordedSets
              ? current?.exercises[i].log?.sets.map(
                  (s): ExerciseLogSet => ({
                    id: uuid(),
                    reps: s.reps?.toString() || '',
                    weight: s.weight?.toString() || '',
                    unit: s.unit,
                    repsPlaceholder: s.repsPlaceholder?.toString() || '',
                    weightPlaceholder: s.weightPlaceholder?.toString() || '',
                    complete: s.complete,
                  })
                )
              : current?.workout?.exercises[i]?.sets.map(
                  (s): ExerciseLogSet => ({
                    id: uuid(),
                    reps: '',
                    weight: '',
                    unit: Unit.Pound,
                    repsPlaceholder: repsPlaceholder(s),
                    weightPlaceholder,
                    complete: false,
                  })
                ),
          }
        }, {}) || {}
      )

      setState({
        timeStop: undefined,
        currentExerciseIndex: data?.current?.exercises?.findIndex((e) => !e.log) || 0,
      })
    },
  })

  const hasSets = (exerciseLog: ExerciseLog): boolean => {
    if (!exerciseLog) return false
    return exerciseLog?.sets?.length > 0
  }

  const repsPlaceholder = (set: WorkoutExerciseSet): string => {
    if (set.reps?.lower && set.reps?.upper) {
      return set.reps.lower === set.reps.upper ? `${set.reps.lower}` : `${set.reps.lower}-${set.reps.upper}`
    }
    return `${set.reps?.lower || set.reps?.upper || '0'}`
  }

  const refresh = async (): Promise<Current> => {
    const { data } = await queryCurrent()
    return data?.current
  }

  const [createExerciseLog] = useMutation<
    WorkoutProviderCreateExerciseLogMutation,
    WorkoutProviderCreateExerciseLogMutationVariables
  >(createExerciseLogMutation)

  const [updateExerciseLog] = useMutation<
    WorkoutProviderUpdateExerciseLogMutation,
    WorkoutProviderUpdateExerciseLogMutationVariables
  >(updateExerciseLogMutation)

  const [stop] = useMutation<StopMutation>(stopWorkout)

  // Workout Exercise Template Info
  const workoutExercises = data?.current?.workout.exercises || []
  const workoutExercise = workoutExercises[state.currentExerciseIndex]

  // Current Workout Exercise Info
  const currentExercises = data?.current?.exercises || []
  const currentExercise = currentExercises[state.currentExerciseIndex]

  const hasPrevious = workoutExercises.length > 0 && state.currentExerciseIndex > 0
  const hasNext = workoutExercises.length - 1 != state.currentExerciseIndex

  const next = async () => {
    if (hasNext) {
      const sets = recordedExerciseSets[currentExercise.id]
      if (sets?.length > 0) {
        recordWorkoutExerciseLog()
      }

      setState((s) => ({
        ...s,
        currentExerciseIndex: s.currentExerciseIndex + 1,
      }))
    }
  }

  const previous = () => {
    if (hasPrevious) {
      setState((s) => ({
        ...s,
        currentExerciseIndex: s.currentExerciseIndex - 1,
      }))
    }
  }

  const recordWorkoutExerciseLog = async () => {
    if (!currentExercise) {
      throw new Error('No current exercise')
    }
    const note = recordedNotes[currentExercise.id]
    const recordedSets = recordedExerciseSets[currentExercise.id]
    if (recordedSets.length === 0) {
      return // nothing to record
    }

    const sets = recordedSets.map(
      (set): ExerciseLogSetInput => ({
        reps: parseInt(set.reps),
        weight: parseFloat(set.weight),
        unit: Unit.Pound,
        repsPlaceholder: parseInt(set.repsPlaceholder || ''),
        weightPlaceholder: parseFloat(set.weightPlaceholder || ''),
        complete: !!set.complete,
      })
    )

    const exerciseLogID = currentExercise.log?.id || ''
    if (recordedExerciseLogIDs.has(exerciseLogID)) {
      await updateExerciseLog({
        variables: {
          exerciseLog: {
            id: exerciseLogID,
            note,
            sets,
          },
        },
      })
      return
    }

    const { data } = await createExerciseLog({
      variables: {
        exerciseLog: {
          exercise: workoutExercise.exercise.id,
          workoutExerciseLog: currentExercise?.id,
          eventDate: new Date(),
          sets: sets,
        },
      },
    })

    currentExercise.log = data?.createExerciseLog
    const newID = data?.createExerciseLog.id || ''
    if (!newID) return
    setRecordedExerciseLogIDs((existingExerciseLogs) => {
      return new Set([...Array.from(existingExerciseLogs), newID])
    })
  }

  const onStop = async () => {
    const sets = recordedExerciseSets[currentExercise.id]
    if (sets.length > 0) {
      await recordWorkoutExerciseLog()
    }

    await stop()
    refresh()
    setState({
      timeStop: undefined,
      currentExerciseIndex: 0,
    })
  }

  useEffect(() => {
    queryCurrent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateNote = (value: string) => {
    setRecordedNotes((logs) => {
      return {
        ...logs,
        [currentExercise.id]: value,
      }
    })
  }

  const removeSet = (log: ExerciseLogSet) => {
    setRecordedExerciseSets((logs) => {
      const id = currentExercise.id
      const sets = logs[id]
      return { ...logs, [id]: sets.filter((s) => s.id !== log.id) }
    })
  }

  const addSet = () => {
    setRecordedExerciseSets((logs) => {
      const id = currentExercise.id
      const sets = logs[id]
      const previousSet = sets[sets.length - 1]
      return {
        ...logs,
        [id]: [
          ...sets,
          {
            id: uuid(),
            reps: previousSet?.reps || '',
            weight: previousSet?.weight || '',
            unit: previousSet?.unit,
            repsPlaceholder: previousSet?.repsPlaceholder || '0',
            weightPlaceholder: previousSet?.weightPlaceholder || '0',
            complete: false,
          },
        ],
      }
    })
  }

  const updateSet = (set: ExerciseLogSet) => {
    setRecordedExerciseSets((logs) => {
      const id = currentExercise.id
      const sets = logs[id]
      const i = sets.findIndex((s) => s.id === set.id)
      sets[i] = { ...set }
      for (let j = i + 1; j < sets.length; j++) {
        sets[j].weightPlaceholder = set.weight
      }
      return {
        ...logs,
        [id]: [...sets],
      }
    })
  }

  const exercise: ExerciseState | undefined = workoutExercise
    ? {
        exercise: {
          id: workoutExercise.id,
          name: workoutExercise.exercise.name,
          exerciseId: workoutExercise.exercise.id,
        },
        note: recordedNotes[currentExercise.id],
        sets: recordedExerciseSets[currentExercise.id],
        removeSet,
        addSet,
        updateSet,
        updateNote,
      }
    : undefined

  return (
    <WorkoutContext.Provider
      value={{
        ...state,
        ...data,
        loading,
        error,
        timer,
        timerInterval,
        isNext: hasNext,
        isPrevious: hasPrevious,
        exercise,
        refresh,
        next,
        previous,
        stop: onStop,
        setTimerInterval,
      }}
    >
      {children}
    </WorkoutContext.Provider>
  )
}
