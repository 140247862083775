import { XMarkIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { Button, Input } from '@nextui-org/react'

type SearchProps = {
  value?: string
  onChange: (value: string) => void
}

export const Search = ({ value, onChange }: SearchProps) => {
  return (
    <Input
      isClearable
      type="text"
      label="Search"
      name="search"
      className="w-full"
      placeholder="Type to search..."
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onClear={() => onChange('')}
      startContent={<MagnifyingGlassIcon className="h-5 w-5" />}
    />
  )
}
