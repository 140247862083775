type Props = {
  className?: string
}
export const HistoryIcon = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} stroke="currentColor" fill="none">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <polyline points="12 8 12 12 14 14" />
    <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
  </svg>
)
