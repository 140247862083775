import { CheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button } from '@nextui-org/react'
import cx from 'classnames'
import React from 'react'
import { AriaButtonProps } from 'react-aria'

type Props = AriaButtonProps & {
  className?: string
  children?: React.ReactNode
  disabled?: boolean
  form?: string
}
export type { Props as ButtonProps }

export const SaveButton = ({ className, disabled, form, ...rest }: Props) => (
  <Button
    isIconOnly
    className={cx('flex h-12 w-12 items-center justify-center px-0 py-0', className)}
    isDisabled={disabled}
    form={form}
    type="submit"
    variant="light"
    {...rest}
  >
    <CheckIcon className="h-6 w-6" />
  </Button>
)

export const RemoveButton = ({ className, disabled, ...rest }: Props) => (
  <Button
    isIconOnly
    className={cx('flex h-12 w-12 items-center justify-center px-0 py-0', className)}
    isDisabled={disabled}
    variant="light"
    {...rest}
  >
    <TrashIcon className="h-6 w-6" />
  </Button>
)
