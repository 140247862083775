import { mergeRefs } from '@react-aria/utils'
import { DOMProps } from '@react-types/shared'
import { AnimationControls, motion, useAnimation } from 'framer-motion'
import { RefObject, createContext, forwardRef, useCallback, useContext, useEffect, useRef } from 'react'
import {
  AriaButtonProps,
  DismissButton,
  FocusScope,
  OverlayContainer,
  mergeProps,
  useButton,
  useDialog,
  useModal,
  useOverlay,
  useOverlayTrigger,
  usePress,
} from 'react-aria'
import { OverlayTriggerState, useOverlayTriggerState } from 'react-stately'
import useMeasure, { RectReadOnly } from 'react-use-measure'

const VELOCITY_TRIGGER = 100
const HEIGHT_BUFFER = 112

type Children = { children?: React.ReactNode }
type DivProps = React.HTMLAttributes<HTMLDivElement>

type Context = {
  triggerRef: RefObject<HTMLDivElement>
  overlayRef: RefObject<HTMLDivElement>
  innerRef: (element: HTMLElement | SVGElement | null) => void
  state: OverlayTriggerState
  triggerProps: AriaButtonProps<'button'>
  overlayProps: DOMProps
  bounds: RectReadOnly
  controls: AnimationControls
  bgControls: AnimationControls
  exit: () => void
  enter: () => void
}

// @ts-ignore
const MenuContext = createContext<Context>(undefined)
const useMenu = () => useContext(MenuContext)

type MenuProps = Children & DivProps & {}

const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu(props, forwardedRef) {
  const bgControls = useAnimation()
  const controls = useAnimation()
  const ref = useRef<HTMLDivElement>(null)
  const triggerRef = useRef(null)
  const overlayRef = useRef(null)
  const state = useOverlayTriggerState({})
  const [innerRef, bounds] = useMeasure()
  const height = bounds.height - HEIGHT_BUFFER
  const exit = useCallback(() => {
    Promise.allSettled([
      controls.start({
        y: window.innerHeight,
        transition: {
          duration: 0.25,
        },
      }),
      bgControls.start({
        opacity: 0,
        transition: {
          duration: 0.25,
        },
      }),
    ]).then(() => state.close())
  }, [state, controls, bgControls])

  const enter = useCallback(() => {
    controls.start(
      {
        y: window.innerHeight - height,
      },
      {
        duration: 0.2,
        type: 'spring',
        damping: 15,
        stiffness: 100,
      }
    )
    bgControls.start(
      {
        opacity: 1,
      },
      {
        duration: 0.3,
      }
    )
  }, [height, controls, bgControls])

  useEffect(() => {
    if (state.isOpen && height > 0) {
      setTimeout(() => {
        enter()
      })
    }
  }, [state.isOpen, height, enter])

  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state, triggerRef)
  const { children } = props

  const context: Context = {
    innerRef,
    overlayRef,
    triggerRef,
    state,
    overlayProps,
    triggerProps,
    bounds,
    controls,
    bgControls,
    exit,
    enter,
  }

  return (
    <div ref={mergeRefs(ref, forwardedRef)}>
      <MenuContext.Provider value={context}>{children}</MenuContext.Provider>
    </div>
  )
})

type TriggerProps = Children & {}

const Trigger = forwardRef<HTMLDivElement, TriggerProps>(function Trigger(props, forwardedRef) {
  const { triggerRef, triggerProps } = useMenu()
  let { pressProps } = usePress({
    ...triggerProps,
  })
  const { children, ...rest } = props

  return (
    <div ref={mergeRefs(triggerRef, forwardedRef)} role="button" {...mergeProps(props, pressProps, rest)}>
      {children}
    </div>
  )
})

type CloseProps = Children & {
  className?: string
}

const Close = forwardRef<HTMLButtonElement, CloseProps>(function Close(props, forwardedRef) {
  const ref = useRef(null)
  const { exit } = useMenu()
  let { buttonProps } = useButton(
    {
      onPress: exit,
    },
    ref
  )
  const { children, ...rest } = props

  return (
    <button ref={mergeRefs(forwardedRef, ref)} {...mergeProps(props, buttonProps, rest)}>
      {children}
    </button>
  )
})

type BottomSheetProps = Children & {}

const BottomSheet = forwardRef<HTMLDivElement, BottomSheetProps>(function BottomSheet(props, forwardedRef) {
  const { overlayRef, state, bounds, innerRef, controls, bgControls, exit, enter } = useMenu()
  const { modalProps } = useModal()
  const { dialogProps } = useDialog({}, overlayRef)
  const { overlayProps } = useOverlay(
    {
      onClose: exit,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    overlayRef
  )

  const height = bounds.height - HEIGHT_BUFFER
  const { children, ...rest } = props
  const y = typeof window != 'undefined' ? window.innerHeight : 0
  const top = typeof window != 'undefined' ? window.innerHeight - height : 0

  return state.isOpen ? (
    <OverlayContainer>
      <motion.div
        className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-black/30 backdrop-blur-sm"
        initial={{
          opacity: 0,
        }}
        animate={bgControls}
      />
      <motion.div
        animate={controls}
        initial={{
          height,
          y,
        }}
        transition={{
          type: 'spring',
          damping: 15,
          stiffness: 100,
        }}
        drag="y"
        dragConstraints={{
          top,
          bottom: -200,
        }}
        dragElastic={{
          top: 0.05,
          bottom: 1,
        }}
        onDragEnd={async (_, { velocity }) => {
          if (velocity.y > VELOCITY_TRIGGER) exit()
        }}
        className="fixed top-0 left-0 right-0 focus:outline-none"
        style={{
          height,
          zIndex: 9999,
        }}
      >
        <FocusScope restoreFocus>
          <div
            {...(mergeProps(overlayProps, dialogProps, rest, modalProps) as any)}
            ref={mergeRefs(forwardedRef, overlayRef, innerRef)}
            className="w-full rounded-t-xl bg-neutral-800 pb-32 focus:outline-none"
          >
            {children}
            <DismissButton onDismiss={state.close} />
          </div>
        </FocusScope>
      </motion.div>
    </OverlayContainer>
  ) : null
})

const pkg = Object.assign(Menu, {
  Trigger,
  BottomSheet,
  Close,
})
export { useMenu as useMenu }
export { pkg as Menu }

