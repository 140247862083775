import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button } from '@nextui-org/react'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { UrlObject } from 'url'

declare type Url = string | UrlObject

type ListItemProps = {
  href: Url
  id?: string
  title: string
}

const ListItem = ({ title, href }: ListItemProps) => {
  const router = useRouter()
  return (
    <li>
      <Button
        className="flex w-full justify-between py-6"
        radius="none"
        variant="light"
        onPress={() => router.push(href)}
      >
        <div>{title}</div>
        <div>
          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      </Button>
    </li>
  )
}

type Props = {
  className?: string
  items: ListItemProps[]
}

export const List = ({ className, items }: Props) => {
  return (
    <div className={cx(className, 'overflow-hidden rounded-md bg-neutral-900 shadow')}>
      <ul role="list" className="divide-y divide-neutral-800">
        {items.map(({ href, id, title }) => (
          <ListItem key={id || title} title={title} href={href} />
        ))}
      </ul>
    </div>
  )
}
