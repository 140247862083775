import { Button } from '@nextui-org/react'
import cx from 'classnames'
import { DockTabs } from 'lib/dock-tabs'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { UrlObject } from 'url'

interface DockTab {
  name: string
  href: UrlObject | string
  activeFor: string[]
  activeIcon?: any
  inactiveIcon?: any
}

interface Props {
  tabs?: Array<DockTab>
}

export const Dock = ({ tabs = DockTabs }: Props) => {
  const router = useRouter()

  const isCurrentTab = (activeFor: string[]): boolean => {
    return !!activeFor.find((path) => router.pathname.startsWith(path || ''))
  }

  return (
    <div className="mb-2 h-20 w-full shrink-0 border-t border-black/50 bg-neutral-950 px-8 md:mb-0 md:px-0">
      <nav className="-mb-px flex justify-between md:justify-center" aria-label="Tabs">
        {tabs.map((tab) => (
          <button
            type="button"
            key={tab.name}
            onClick={() => router.push(tab.href)}
            aria-current={isCurrentTab(tab.activeFor) ? 'page' : undefined}
          >
            <div className="px-1 py-4">
              <div className="mx-8 flex flex-col items-center">
                <div>
                  {tab.inactiveIcon && !isCurrentTab(tab.activeFor) && <tab.inactiveIcon className="h-6 w-6" />}
                </div>
                <div>
                  {tab.activeIcon && isCurrentTab(tab.activeFor) && (
                    <tab.activeIcon className="h-6 w-6 fill-amber-500" />
                  )}
                </div>
                <div className={cx('text-xs font-semibold', isCurrentTab(tab.activeFor) && 'text-amber-500')}>
                  {tab.name}
                </div>
              </div>
            </div>
          </button>
        ))}
      </nav>
    </div>
  )
}
