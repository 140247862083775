import { ApolloProvider } from '@apollo/client'
import { NextUIProvider } from '@nextui-org/react'
import { WorkoutProvider } from 'components'
import * as Fathom from 'fathom-client'
import { fetcher } from 'lib'
import apolloClient from 'lib/apollo'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { OverlayProvider } from 'react-aria'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { SWRConfig } from 'swr'
import colors from 'tailwindcss/colors'
import '../styles/globals.css'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    Fathom.load('ARIISWCE', {
      includedDomains: ['fitzero.io'],
      url: 'https://cdn.usefathom.com/script.js',
    })
    function onRouteChangeComplete() {
      Fathom.trackPageview()
    }
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NextUIProvider>
      <Head>
        <title>FitZero</title>
        <meta name="application-name" content="FitZero" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="FitZero" />
        <meta name="description" content="Track your fitness" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="msapplication-TileColor" content="black" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content={colors.neutral['950']} />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />

        {/* Icons */}
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />

        <link rel="manifest" href="/site.webmanifest" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <SWRConfig
        value={{
          fetcher,
        }}
      >
            <main className="dark text-foreground">
              <ApolloProvider client={apolloClient}>
                <OverlayProvider>
                  <WorkoutProvider>
                    <Component {...pageProps} />
                    <ToastContainer
                      limit={1}
                      position="bottom-center"
                      closeButton={false}
                      transition={Zoom}
                      draggableDirection="y"
                      autoClose={5000}
                      icon={false}
                      className="!w-[80vw]"
                      toastClassName={() =>
                        'bg-neutral-50 relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'
                      }
                      bodyClassName={() => 'w-full text-sm text-black font-med block p-3'}
                    />
                  </WorkoutProvider>
                </OverlayProvider>
              </ApolloProvider>
            </main>
      </SWRConfig>
    </NextUIProvider>
  )
}

export default MyApp
