import { BoltIcon, HomeIcon, RectangleStackIcon } from '@heroicons/react/24/outline'
import {
  BoltIcon as SolidBoltIcon,
  HomeIcon as SolidHomeIcon,
  RectangleStackIcon as SolidRectangleStackIcon,
} from '@heroicons/react/24/solid'
import { Route } from './route'

export const DockTabs = [
  {
    href: Route.Home,
    name: 'Home',
    activeFor: [Route.Home],
    inactiveIcon: HomeIcon,
    activeIcon: SolidHomeIcon,
  },
  {
    href: Route.Exercises,
    name: 'Exercises',
    activeFor: [Route.Exercises, Route.Workouts, Route.Routines],
    inactiveIcon: BoltIcon,
    activeIcon: SolidBoltIcon,
  },
  {
    href: Route.ExerciseLogs,
    name: 'Logs',
    activeFor: [Route.ExerciseLogs],
    inactiveIcon: RectangleStackIcon,
    activeIcon: SolidRectangleStackIcon,
  },
]
