import { PlayIcon } from '@heroicons/react/24/solid'
import cx from 'classnames'
import { motion, useAnimation } from 'framer-motion'
import { useRef } from 'react'
import { useButton } from 'react-aria'
import colors from 'tailwindcss/colors'
import { ButtonProps } from './button'

export const StartButton = ({ className, isDisabled, ...rest }: ButtonProps) => {
  const controls = useAnimation()
  const ref = useRef<HTMLButtonElement>(null)
  const { buttonProps } = useButton(
    {
      onPressStart: () => {
        controls.stop()
        controls.set({
          backgroundColor: colors.amber[600],
          scale: 0.97,
        })
      },
      onPressEnd: () => {
        controls.start({
          backgroundColor: colors.amber[500],
          scale: 1.0,
          transition: { duration: 0.2 },
        })
      },
      ...rest,
    },
    ref
  )

  return (
    <motion.button
      animate={controls}
      ref={ref}
      className={cx(
        'webkit-highlight-transparent relative inline-flex h-12 w-12 touch-none select-none items-center justify-center rounded-full bg-amber-500 text-black shadow-md shadow-black focus:outline-none',
        className
      )}
      {...(buttonProps as any)}
    >
      <PlayIcon className="h-5 w-5" />
    </motion.button>
  )
}
