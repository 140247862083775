import cx from 'classnames'

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

export const Page = ({ className, children }: Props) => <div className={cx('h-screen', className)}>{children}</div>

export const Main = ({ className, children }: Props) => <div className={className}>{children}</div>

export const Content = ({ className, children }: Props) => (
  <div className={cx('container mx-auto px-2 py-4 sm:px-6 lg:px-8', className)}>{children}</div>
)
