export enum Route {
  Home = '/home',
  LoginFailed = '/login/failed',
  Root = '/',
  Workouts = '/workouts',
  Workout = '/workouts/[workoutId]',
  WorkoutNew = '/workouts/new',
  WorkoutEdit = '/workouts/[workoutId]/edit',
  WorkoutAdd = '/workouts/[workoutId]/add',
  WorkoutExercise = '/workouts/[workoutId]/exercises/[exerciseId]',
  Exercises = '/exercises',
  Exercise = '/exercises/[exerciseId]',
  ExerciseEdit = '/exercises/[exerciseId]/edit',
  ExerciseLogs = '/exerciselogs',
  ExerciseLogNew = '/exerciselogs/new',
  ExerciseLog = '/exerciselogs/[exerciseLogId]',
  Signup = '/signup',
  Routines = '/routines',
  Routine = '/routines/[routineId]',
  RoutineEdit = '/routines/[routineId]/edit',
  RoutineNew = '/routines/new',
  RoutineAdd = '/routines/[routineId]/add',
  RoutineWorkout = '/routines/[routineId]/workouts/[workoutId]',
  RoutineWorkoutExercise = '/routines/[routineId]/workouts/[workoutId]/exercises/[exerciseId]',
  Login = '/login',
  Live = '/live',
}

export const isApp = (r: string) => ![Route.Root, Route.Login, Route.Signup].includes(r as Route)
