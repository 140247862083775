import { ChevronUpIcon } from '@heroicons/react/24/outline'
import { motion, useAnimation } from 'framer-motion'
import { isApp, Route } from 'lib'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useButton } from 'react-aria'
import { useWorkout } from './workout-provider'
import { Button, Card, CardBody } from '@nextui-org/react'

export const WorkoutBar = () => {
  const controls = useAnimation()
  const ref = useRef(null)
  let { buttonProps } = useButton(
    {
      onPressStart: () => {
        controls.start({
          scale: 0.95,
        })
      },
      onPressEnd: () => {
        controls.start({
          scale: 1.0,
        })
      },
      onPress: () => {
        router.push({
          pathname: Route.Live,
          query: {
            return_to: router.asPath,
          },
        })
      },
    },
    ref
  )
  const router = useRouter()
  const { current } = useWorkout()
  const pageWithBar = isApp(router.pathname) && router.pathname !== Route.Live
  const showLive = pageWithBar && current
  const onPress = () => {
    router.push({
      pathname: Route.Live,
      query: {
        return_to: router.asPath,
      },
    })
  }

  return showLive ? (
    <Card
      isPressable
      className="h-20 w-full shrink-0 touch-none select-none rounded-lg bg-amber-700 py-2 text-left focus:outline-none"
      onPress={onPress}
    >
      <CardBody>
        <div className="flex items-center">
          <ChevronUpIcon className="mr-2 inline-flex h-6 w-6" />
          {current.workout.name}
        </div>
      </CardBody>
    </Card>
  ) : null
}
