import { CheckIcon, ChevronLeftIcon, PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from '@nextui-org/react'
import Link, { LinkProps } from 'next/link'
import router from 'next/router'
import colors from 'tailwindcss/colors'

type LinkType = 'primary' | 'secondary' | 'icon'

export const buttonStyles = {
  primary: {
    base: {
      color: colors.neutral[50],
      borderColor: colors.amber[500],
      borderWidth: '2px',
      fill: colors.amber[500],
      stroke: colors.amber[500],
      '--border-color': colors.amber[500],
    },
    disabled: {
      color: colors.neutral[600],
      borderColor: colors.neutral[600],
      fill: colors.amber[600],
      stroke: colors.amber[600],
      borderWidth: '2px',
      '--border-color': colors.neutral[600],
    },
    pressed: {
      color: colors.neutral[300],
      borderColor: colors.amber[700],
      fill: colors.amber[700],
      stroke: colors.amber[700],
      borderWidth: '2px',
      '--border-color': colors.amber[700],
    },
  },
  secondary: {
    base: {
      color: colors.neutral[50],
      borderColor: colors.neutral[50],
      borderWidth: '2px',
      '--border-color': colors.neutral[50],
    },
    disabled: {
      color: colors.neutral[600],
      borderColor: colors.neutral[600],
      borderWidth: '2px',
      '--border-color': colors.neutral[600],
    },
    pressed: {
      color: colors.neutral[300],
      borderColor: colors.neutral[300],
      borderWidth: '2px',
      '--border-color': colors.neutral[300],
    },
  },
  icon: {
    base: {
      color: colors.neutral[50],
    },
    disabled: {
      color: colors.neutral[600],
    },
    pressed: {
      color: colors.neutral[300],
    },
  },
}

type Props = LinkProps & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>

export const Add = ({ className, ...rest }: Props) => {
  return (
    <Link className={className} {...rest}>
      <Button isIconOnly variant="light" type="button" className="mx-2">
        <PlusIcon className="h-6 w-6" />
      </Button>
    </Link>
  )
}

export const Back = () => (
  <Button isIconOnly variant="light" type="button" onClick={router.back} className="mx-2">
    <ChevronLeftIcon className="h-6 w-6" />
  </Button>
)

export const Close = () => (
  <Button isIconOnly variant="light" type="button" onClick={router.back} className="mx-2">
    <XMarkIcon className="h-6 w-6" />
  </Button>
)

export const Edit = ({ className, ...rest }: Props) => {
  return (
    <Link className={className} {...rest}>
      <Button isIconOnly variant="light" type="button" className="mx-2">
        <PencilIcon className="h-5 w-5" />
      </Button>
    </Link>
  )
}

export const Save = () => (
  <Button isIconOnly variant="light" type="submit" className="mx-2">
    <CheckIcon className="h-6 w-6" />
  </Button>
)
