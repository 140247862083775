import { Spinner } from '@nextui-org/react'
import { useCallback, useEffect, useRef } from 'react'

type Props = {
  loadFn: () => any
  loading?: boolean
}

export const Loader = ({ loadFn, loading }: Props) => {
  const loader = useRef(null)

  const handleObserver = useCallback(
    async (entries: any) => {
      const target = entries[0]
      if (target.isIntersecting) {
        loadFn()
      }
    },
    [loadFn]
  )

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '40px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) observer.observe(loader.current)
  }, [handleObserver])

  return loading ? (
    <div className="flex justify-center">
      <Spinner size="md" />
    </div>
  ) : (
    <div className="h-2" ref={loader} />
  )
}
