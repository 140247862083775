export const ordinal = (n: number) => {
  const ordinalRules = new Intl.PluralRules('en', {
    type: 'ordinal',
  })
  const suffixes: Record<Intl.LDMLPluralRule, string> = {
    zero: 'th',
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
    many: 'th',
  }
  const suffix = suffixes[ordinalRules.select(n)]
  return n + suffix
}
