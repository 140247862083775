import { Bars2Icon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import { DropIndicator, Item, ListBox, useDragAndDrop } from 'react-aria-components'
import { useListData } from 'react-stately'

interface ReorderabelListItem {
  id: string | number
  name: string
}

interface Props {
  className?: string
  items: ReorderabelListItem[]
  callback: (items: ReorderabelListItem[]) => void
}

export const ReorderableList = ({ className, items, callback }: Props) => {
  const list = useListData({
    initialItems: items,
  })

  const { dragAndDropHooks } = useDragAndDrop({
    getItems: (keys) => [...keys].map((key) => ({ 'text/plain': list.getItem(key).name })),
    onReorder(e) {
      if (e.target.dropPosition === 'before') {
        list.moveBefore(e.target.key, e.keys)
      } else if (e.target.dropPosition === 'after') {
        list.moveAfter(e.target.key, e.keys)
      }
    },
    onDragEnd(e) {
      callback(list.items)
    },
    async onInsert(e) {
      let items = await Promise.all(
        e.items.map(async (item) => {
          let name = item.kind === 'text' ? await item.getText('text/plain') : item.name
          return { id: Math.random(), name }
        })
      )

      if (e.target.dropPosition === 'before') {
        list.insertBefore(e.target.key, ...items)
      } else if (e.target.dropPosition === 'after') {
        list.insertAfter(e.target.key, ...items)
      }
    },
    renderDropIndicator(target) {
      return (
        <DropIndicator
          target={target}
          className={({ isDropTarget }: any) => `${isDropTarget ? 'outline outline-1 outline-amber-500' : ''}`}
        />
      )
    },
  })

  return (
    <ListBox
      aria-label="Reorderable list"
      selectionMode="multiple"
      items={list.items}
      dragAndDropHooks={dragAndDropHooks}
      className={cx('divide-y divide-neutral-800 rounded-md bg-neutral-900', className)}
    >
      {(item) => (
        <Item className="px-6 hover:bg-neutral-800 active:bg-neutral-700">
          <div className="flex w-full justify-between py-4">
            <div>{item.name}</div>
            <div>
              <Bars2Icon className="h-5 w-5 text-gray-400 hover:cursor-grab" aria-hidden="true" />
            </div>
          </div>
        </Item>
      )}
    </ListBox>
  )
}
