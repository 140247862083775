import { useEffect, useRef } from 'react'

type Callback = () => void

// https://github.com/amrlabib/react-timer-hook/blob/master/src/hooks/useInterval.js
export default function useInterval(
  callback: Callback,
  delay: number | undefined | null
) {
  const callbacRef = useRef<Callback | null>(null)

  // update callback function with current render callback that has access to latest props and state
  useEffect(() => {
    callbacRef.current = callback
  })

  useEffect(() => {
    if (!delay) {
      return () => {}
    }
    const interval = setInterval(() => {
      callbacRef.current && callbacRef.current()
    }, delay)
    return () => clearInterval(interval)
  }, [delay])
}
