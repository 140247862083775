import * as Sentry from '@sentry/nextjs'
import { toast } from 'react-toastify'

export const handleError = (
  err: any,
  msg: string = 'Whoops! Please try that again.'
) => {
  console.error(err)
  Sentry.captureException(err)
  toast.error(msg)
}
