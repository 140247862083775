import { CalendarIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Card, CardBody, CardFooter, CardHeader, Divider } from '@nextui-org/react'
import { Unit } from 'lib/generated'
import { useRouter } from 'next/router'
import { UrlObject } from 'url'

interface ExerciseLogSet {
  setNumber: number
  reps?: number | null
  weight?: number | null
  unit: Unit
}

interface ExerciseLogCardProps {
  href: UrlObject | string
  title?: string
  eventDate: string
  sets: ExerciseLogSet[]
  note?: string | null
}

export const ExerciseLogCard = ({ href, title, eventDate, sets, note }: ExerciseLogCardProps) => {
  const router = useRouter()
  const df = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' })
  const displayUnit = (unit: Unit): string => {
    switch (unit) {
      case Unit.Hour:
        return 'h'
      case Unit.Kilogram:
        return 'kg'
      case Unit.Minute:
        return 'm'
      case Unit.Pound:
        return 'lb'
      case Unit.Second:
        return 's'
    }
  }
  return (
    <Card className="w-full" isPressable onPress={() => router.push(href)}>
      <CardHeader>
        <div className="flex flex-col gap-2">
          {title && <div className="flex justify-start px-4 text-xl font-bold">{title}</div>}
          <div className="flex flex-row items-center gap-1 px-4">
            <CalendarIcon className="h-4 w-4" />
            <div className="text-md">{df.format(new Date(eventDate))}</div>
          </div>
        </div>
      </CardHeader>
      <Divider />
      <CardBody>
        <div className="sm:px">
          <div className="flex flex-col gap-2">
            {sets.map((set) => (
              <div key={set.setNumber} className="flex flex-row items-center gap-4 px-4 text-sm text-gray-300">
                <div className="font-mono">
                  <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                    {set.setNumber}
                  </span>
                </div>
                <div className="flex flex-row items-center gap-1">
                  {`${set.reps} reps`} <XMarkIcon className="h-3 w-3" />
                  {`${set.weight} ${displayUnit(set.unit)}s`}
                </div>
              </div>
            ))}
          </div>
          {note && (
            <div className="flex w-full items-center gap-1 pt-4 text-gray-400">
              <PencilSquareIcon className="h-5 w-5" />
              {note}
            </div>
          )}
        </div>
      </CardBody>
      <CardFooter className="flex w-full justify-center rounded-b-md border border-neutral-700 bg-neutral-700 py-2 font-semibold">
        VIEW
      </CardFooter>
    </Card>
  )
}
